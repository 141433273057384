import api from "../axios_service.js";

export async function index(id) {
  const response = await api.get(`/admin/networking/usuario/${id}`);
  return response.data.result;
}

export async function show(id) {
  const response = await api.get(`/admin/networking/${id}`);
  return response.data.result;
}

export function deleteNetworking(id) {
  return api.delete(`/admin/networking/${id}`);
}

export function create(networking) {
  return api.post(`/admin/networking`, networking);
}
